export default {
  methods: {
    findSubCategoryById(mainCategory,id) {
      if (mainCategory) {
        return mainCategory.subCategory.find(function (subCategory) {
              const found = subCategory.subCategory.find((sc) => parseInt(sc.id) === parseInt(id))
              return !!found;
            }
        );
      }
      return null;
    },
    findCategoryById(id) {
      return this.categories.find(
          (category) => parseInt(category.id) === parseInt(id)
      );
    },
    findCategoryBySlug(slug) {
      return this.categories.find(
          (category) => this.$formatUrlhelloBB(category.name) === slug
      );
    },
  }
}