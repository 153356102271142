import {mapGetters} from "vuex";
import FindCategories from "../modules/catalogue/components/mixins/FindCategories";

export default {
  computed: {
    ...mapGetters({
      categories: "categories",
      categoriesPrefetched: "categoriesPrefetched",
    }),
  },
  mixins: [FindCategories],
  methods: {
    async getCategories(server=false) {
      return this.$store.dispatch("getCategories",server);
    },
    async getCategoriesFromServer() {
        return await this.getCategories(true)
    },
    setSelectedCategory(to = null) {
      let route = this.$route
      if (to) route = to
      let selectedCategory = {}
      if (this.$route.name === 'category') {
        selectedCategory = {
          id: parseInt(this.$route.params.id),
          name: this.$formatUrlhelloBB(route.params.name),
          level: 1,
          subCategories: [],
          mainCategory: this.$formatUrlhelloBB(route.params.name),
          mainCategoryId: route.params.id,
        }
      }
      if (route.name === 'subcategory') {
        let mainCategory = this.findCategoryBySlug(route.params.mainCategory)
        let subCategories = [];
        if (route.query?.subCategory) {
          // TODO Hack brut! A level 3 subcategories en comptes de ser [] (últim nivell) conté la categoria pare de nivell 2
          subCategories = this.findSubCategoryById(mainCategory, route.params.id)
        }
        selectedCategory = {
          id: parseInt(route.params.id),
          name: this.$formatUrlhelloBB(route.params.name),
          level: route.query?.subCategory ? 3 : 2,
          subCategories: subCategories,
          mainCategory: mainCategory?.name,
          mainCategoryId: mainCategory?.id,
        }
      }
      this.$store.commit("saveSelectedCategory", selectedCategory);
    },
    getTranslatedCategory(categoryId) {
      const category = this.findCategoryById(categoryId);
      if (!category) {
        return "";
      }

      const translationKey = category?.translationKey;
      if (!translationKey) {
        return "";
      }

      return this.$t(`categories.${translationKey}`);
    },
  },
  async serverPrefetch() {
    await this.getCategoriesFromServer();
    this.setSelectedCategory()
  },
  async mounted() {
    if (!this.categoriesPrefetched) {
      await this.getCategories()
    }
  }
}
